var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c("GeneralEdit", {
        ref: "GeneralEdit",
        attrs: {
          "table-name": "t_resource",
          "row-key": "c_id",
          url: _vm.url,
          id: _vm.id,
          rules: _vm.rules,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }