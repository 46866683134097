//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralEdit from '@/components/GeneralEdit';
export default {
  name: 'Index',
  components: {
    GeneralEdit: GeneralEdit
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      rules: {
        b_resource_code: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'blur'
        }, {
          min: 0,
          max: 50,
          message: this.$t("rules.common.maxlength50"),
          trigger: 'blur'
        }],
        b_resource_name: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'blur'
        }, {
          min: 0,
          max: 100,
          message: this.$t("rules.common.maxlength100"),
          trigger: 'blur'
        }],
        b_resource_name_e: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'blur'
        }, {
          min: 0,
          max: 100,
          message: this.$t("rules.common.maxlength100"),
          trigger: 'blur'
        }],
        b_resource_type: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    url: function url() {
      if (this.$route.params.id) {
        return {
          getUrl: '/resource',
          method: 'put'
        };
      } else {
        return {
          getUrl: '/resource',
          method: 'post'
        };
      }
    }
  }
};